import { GridApi } from 'ag-grid-community';
import { updateMSSReferenceData } from 'app/api/mss/mss';
import useToast from 'app/hooks/useToast';
import { useState } from 'react';

export const mssDefaultValues = {
    'mss-ss-expectedPeople': {
        AIRLINE: '',
        CATEGORY: '',
        AIRPORT: '',
        LOAD_FACTOR: 0,
        MEET_AND_GREETERS_PER_PAX: 0
    },
    'mss-ss-machineThroughput': {
        MACHINE: '',
        NUMBER_OF_OPERATORS: 0,
        THROUGHPUT_PER_HOUR: 0
    },
    'mss-ss-presentationTime': {
        AIRLINE: '',
        AIRPORT: '',
        CATEGORY: '',
        PORTION: 0,
        MINUTES_BEFORE_FLIGHT: 0
    },
    'mss-ss-siteFlightMapping': {
        AIRLINE: '',
        AIRPORT: '',
        CATEGORY: '',
        SITE: ''
    },
    'mss-ss-siteSpecifications': {
        TERMINAL: '',
        AIRPORT: '',
        SITE: '',
        MACHINES: 0,
        MACHINE_TYPE: ''
    },
    'mss-ss-speedFactors': {
        AIRLINE: '',
        CATEGORY: '',
        MARKET: '',
        FACTOR: 0
    }
};

const useAddRow = (refetch: any, gridApi: GridApi | null, reportKey: keyof typeof mssDefaultValues) => {
    const { setNotification } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const handleAddRow = async () => {
        const newDataItem = mssDefaultValues[reportKey];

        try {
            setIsLoading(true);
            await updateMSSReferenceData([newDataItem], reportKey.split('-')[2]);
            refetch();
            setNotification('Added a row', 'success');

            if (gridApi) {
                gridApi.paginationGoToLastPage();
            }
        } catch (error) {
            setNotification('Failed to add a row', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return { handleAddRow, isLoading };
};

export default useAddRow;
